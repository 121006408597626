.app-header {
  align-items: flex-end;
  justify-content: space-between;

}

.app-header h1 {

  font-size: 26px;
}

.app-header h2 {
  font-size: 1.2rem;
  color: grey;
}